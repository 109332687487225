<i18n src="./form-extinguishing-bottles-i18n.yaml"></i18n>
<template>
    <v-form :readonly="readonlyModus" :disabled="disabled">
        <app-dev-container>
            <template v-slot:content>
                <base-btn icon color="warning" @click="setFakeData">
                    <v-icon>mdi-bug</v-icon>
                </base-btn>
                <base-btn icon color="yellow" @click="reset">
                    <v-icon>mdi-refresh</v-icon>
                </base-btn>
                @Validation:
                <tree-view :data="$v" :options="{ maxDepth: 0 }"></tree-view>
            </template>
        </app-dev-container>

        <v-row dense>
            <v-col class="py-0" sm="6">
                <WNumericField
                    :readonly="readonlyModus"
                    :options="fieldsOptions.bottlesCount"
                    v-model="bottlesCount"
                    :disabled="disabled"
                    :minValue="$v.extinguishingBottles.bottlesCount.$params.minValue.min"
                    :maxValue="$v.extinguishingBottles.bottlesCount.$params.maxValue.max"
                    :label="$t('bottles-count-label')"
                    :hint="
                        $t('hint', {
                            minValue: $v.extinguishingBottles.bottlesCount.$params.minValue.min,
                            maxValue: $v.extinguishingBottles.bottlesCount.$params.maxValue.max
                        })
                    "
                    :error-messages="bottlesCountErrors"
                    :required="isRequiredField($v.extinguishingBottles.bottlesCount)"
                >
                </WNumericField>
            </v-col>
            <v-col class="py-0" cols="12" sm="6">
                <!-- volume -->
                <WDecimalField
                    :disabled="disabled"
                    :readonly="readonlyModus"
                    @input="volume = $event"
                    :options="fieldsOptions.volume"
                    :value="volume"
                    :suffix="$t('label-liter')"
                    :label="$t('volume-label')"
                    :placeholder="
                        $t('placeholder', {
                            value: 24.4,
                            unit: $t('label-liter')
                        })
                    "
                    :hint="
                        $t('hint', {
                            minValue: $v.extinguishingBottles.volume.$params.minValue.min,
                            maxValue: $v.extinguishingBottles.volume.$params.maxValue.max
                        })
                    "
                    :error-messages="volumeErrors"
                    append-icon="mdi-information-outline"
                    @click:append="showFaq('chambersVolume')"
                    :required="isRequiredField($v.extinguishingBottles.volume)"
                >
                </WDecimalField>
            </v-col>
        </v-row>

        <v-row dense>
            <v-col class="py-0" cols="12" sm="6">
                <!-- min. zul. Druck (in bar, -+, relativ zur Meereshöhe) -->
                <WDecimalField
                    :disabled="disabled"
                    :readonly="readonlyModus"
                    :options="fieldsOptions.lastMinPressure"
                    @input="lastMinPressure = $event"
                    v-model="lastMinPressure"
                    :suffix="$t('label-bar')"
                    :label="$t('last-min-pressure-label')"
                    :hint="
                        $t('hint', {
                            minValue: $v.extinguishingBottles.lastMinPressure.$params.minValue.min,
                            maxValue: $v.extinguishingBottles.lastMinPressure.$params.maxValue.max
                        })
                    "
                    :placeholder="
                        $t('placeholder', {
                            value: 24.4,
                            unit: $t('label-bar')
                        })
                    "
                    :error-messages="lastMinPressureErrors"
                    append-icon="mdi-information-outline"
                    @click:append="showFaq('chambersLastMinPressure')"
                    :required="isRequiredField($v.extinguishingBottles.lastMinPressure)"
                >
                </WDecimalField>
            </v-col>
            <v-col class="py-0" cols="12" sm="6">
                <!-- max. zul. Druck (in bar, +, relativ zur Meereshöhe) -->
                <WDecimalField
                    :disabled="disabled"
                    :readonly="readonlyModus"
                    :options="fieldsOptions.lastMaxPressure"
                    @input="lastMaxPressure = $event"
                    v-model="lastMaxPressure"
                    :suffix="$t('label-bar')"
                    :label="$t('last-max-pressure-label')"
                    :hint="
                        $t('hint', {
                            minValue: $v.extinguishingBottles.lastMaxPressure.$params.minValue.min,
                            maxValue: $v.extinguishingBottles.lastMaxPressure.$params.maxValue.max
                        })
                    "
                    :placeholder="
                        $t('placeholder', {
                            value: 24.4,
                            unit: $t('label-bar')
                        })
                    "
                    :error-messages="lastMaxPressureErrors"
                    append-icon="mdi-information-outline"
                    @click:append="showFaq('chambersLastMaxPressure')"
                    :required="isRequiredField($v.extinguishingBottles.lastMaxPressure)"
                >
                </WDecimalField>
            </v-col>
        </v-row>

        <v-row dense>
            <v-col class="py-0" cols="12" sm="6">
                <!-- min. zul. Konzessionstemperatur (in °C) -->
                <WDecimalField
                    :disabled="disabled"
                    :readonly="readonlyModus"
                    :options="fieldsOptions.lastMinConcessionTemperature"
                    @input="lastMinConcessionTemperature = $event"
                    :value="lastMinConcessionTemperature"
                    :suffix="$t('label-celsius')"
                    :label="$t('last-min-concession-temperature-label')"
                    :hint="
                        $t('hint', {
                            minValue:
                                $v.extinguishingBottles.lastMinConcessionTemperature.$params.minValue
                                    .min,
                            maxValue:
                                $v.extinguishingBottles.lastMinConcessionTemperature.$params.maxValue
                                    .max
                        })
                    "
                    :placeholder="
                        $t('placeholder', {
                            value: 24.4,
                            unit: $t('label-celsius')
                        })
                    "
                    :error-messages="lastMinConcessionTemperatureErrors"
                    append-icon="mdi-information-outline"
                    @click:append="
                        showFaq('chambersLastMinConcessionTemperature')
                    "
                    :required="isRequiredField($v.extinguishingBottles.lastMinConcessionTemperature)"
                >
                </WDecimalField>
            </v-col>
            <v-col class="py-0" cols="12" sm="6">
                <!-- max. zul. Konzessionstemperatur (in °C) -->
                <WDecimalField
                    :disabled="disabled"
                    :readonly="readonlyModus"
                    :options="fieldsOptions.lastMaxConcessionTemperature"
                    @input="lastMaxConcessionTemperature = $event"
                    :value="lastMaxConcessionTemperature"
                    :suffix="$t('label-celsius')"
                    :label="$t('last-max-concession-temperature-label')"
                    :hint="
                        $t('hint', {
                            minValue:
                                $v.extinguishingBottles.lastMaxConcessionTemperature.$params.minValue
                                    .min,
                            maxValue:
                                $v.extinguishingBottles.lastMaxConcessionTemperature.$params.maxValue
                                    .max
                        })
                    "
                    :placeholder="
                        $t('placeholder', {
                            value: 24.4,
                            unit: $t('label-celsius')
                        })
                    "
                    :error-messages="lastMaxConcessionTemperatureErrors"
                    append-icon="mdi-information-outline"
                    @click:append="
                        showFaq('chambersLastMaxConcessionTemperature')
                    "
                    :required="isRequiredField($v.extinguishingBottles.lastMaxConcessionTemperature)"
                >
                </WDecimalField>
            </v-col>
        </v-row>
        <v-row dense>
            <v-col cols="12" md="6">
                <!-- prüfdruck -->
                <WDecimalField
                    :disabled="disabled"
                    :readonly="readonlyModus"
                    :options="fieldsOptions.testPressure"
                    @input="testPressure = $event"
                    :value="testPressure"
                    :label="$t('test-pressure-label')"
                    :suffix="$t('label-bar')"
                    :hint="
                        $t('hint', {
                            minValue: $v.extinguishingBottles.testPressure.$params.minValue.min,
                            maxValue: $v.extinguishingBottles.testPressure.$params.maxValue.max
                        })
                    "
                    :placeholder="
                        $t('placeholder', {
                            value: 24.4,
                            unit: $t('label-bar')
                        })
                    "
                    :error-messages="testPressureErrors"
                    append-icon="mdi-information-outline"
                    @click:append="showFaq('chambersTestPressure')"
                    :required="isRequiredField($v.extinguishingBottles.testPressure)"
                >
                </WDecimalField>
            </v-col>
            <v-col cols="12" md="6">
            </v-col>
        </v-row>
        <!-- stoffCode -->
        <base-autocomplete
            :items="sortedMaterialCodeItems"
            v-model="materialCode"
            :label="$t('material-code-label')"
            item-value="key"
            :error-messages="materialCodeErrors"
            :clearable="!readonlyModus"
            append-icon="mdi-information-outline"
            @click:append="showFaq('chambersMaterialCode')"
            :required="isRequiredField($v.extinguishingBottles.materialCode)"
            :filter="customFilter"
        >
            <template v-slot:item="{ item }">
                <span v-if="item.value[$i18n.locale]">
                    {{ item.value[$i18n.locale] }}
                </span>
                <span v-else class="font-italic">
                    {{ item.value.de }} (de)
                </span>
            </template>
            <template v-slot:selection="{ item }">
                <!-- use vuetify class to break the text if is too large  -->
                <div class="v-select__selection v-select__selection--comma">
                    <span v-if="item.value[$i18n.locale]">
                        {{ item.value[$i18n.locale] }}
                    </span>
                    <span v-else class="font-italic">
                        {{ item.value.de }} (de)
                    </span>
                </div>
            </template>
        </base-autocomplete>

        <base-select
            v-model="lastTestDate"
            :items="testYearItems"
            :label="$t('last-test-date')"
            :errorMessages="lastTestDateErrors"
            :required="isRequiredField($v.extinguishingBottles.lastTestDate)"
        >
            <template v-slot:item="{ item }">
                <span v-if="testYearItems.indexOf(item) === 0"
                    >{{ item }} ({{ $t("this-year") }})</span
                >
                <span
                    v-else-if="
                        testYearItems.indexOf(item) === 1
                    "
                    >{{ item }} ({{ $t("last-year") }})</span
                >
                <span
                    v-else-if="
                        testYearItems.indexOf(item) === 2
                    "
                    >{{ item }} ({{ $t("two-years-ago") }})</span
                >
                <span v-else>{{ item }}</span>
            </template>
        </base-select>

        <v-row dense>
            <v-col cols="12">
                <base-textarea
                    v-model="coverage"
                    :error-messages="coverageErrors"
                    :label="$t('coverage-label')"
                    :clearable="!readonlyModus"
                    :counter="
                        $v.extinguishingBottles.coverage.$params.maxLength.max
                    "
                    :required="isRequiredField($v.extinguishingBottles.coverage)"
                >
                </base-textarea>
            </v-col>
        </v-row>
    </v-form>
</template>
<script>
import {
    required,
    maxLength,
    minValue,
    maxValue,
    helpers
} from "vuelidate/lib/validators";
import WDecimalField from "@/components/fields/WDecimalField.vue";
import WNumericField from "@/components/fields/WNumericField.vue";
import { mapActions } from "vuex";
import { sortAttributesAlphabetically } from "@/utils";
import _ from "lodash";
import validateMixin from "@/mixins/validate";

const greaterThan = (param) =>
    function (value) {
        if (!helpers.req(value)) {
            return true;
        } else if (isNaN(param)) {
            return true;
        } else {
            if (param) {
                //parseFloat to be sure that de decimal value will be compared
                return parseFloat(value) >= parseFloat(param);
            } else {
                return true;
            }
        }
    };

const lesserThan = (param) =>
    function (value) {
        if (!helpers.req(value)) {
            return true;
        } else if (isNaN(param)) {
            return true;
        } else {
            if (param) {
                //parseFloat to be sure that de decimal value will be compared
                return parseFloat(value) <= parseFloat(param);
            } else {
                return true;
            }
        }
    };

export default {
    name: "FormExtinguishingBottles",
    mixins: [validateMixin],
    validations() {
        return {
            extinguishingBottles: {
                bottlesCount: {
                    required,
                    minValue: minValue(
                        this.fieldsOptions.bottlesCount.minimumValue
                    ),
                    maxValue: maxValue(
                        this.fieldsOptions.bottlesCount.maximumValue
                    ),
                },
                volume: {
                    required,
                    minValue: minValue(0.1),
                    maxValue: maxValue(this.fieldsOptions.volume.maximumValue)
                },
                lastMinConcessionTemperature: {
                    minValue: minValue(
                        this.fieldsOptions.lastMinConcessionTemperature.minimumValue
                    ),
                    maxValue: maxValue(
                        this.fieldsOptions.lastMinConcessionTemperature.maximumValue
                    ),
                    lesserThan: lesserThan(this.extinguishingBottles.lastMaxConcessionTemperature)
                },
                lastMaxConcessionTemperature: {
                    required,
                    minValue: minValue(
                        this.extinguishingBottles.lastMinConcessionTemperature > 0
                            ? this.extinguishingBottles.lastMinConcessionTemperature
                            : 0.1
                    ),
                    maxValue: maxValue(
                        this.fieldsOptions.lastMaxConcessionTemperature.maximumValue
                    ),
                    greaterThan: greaterThan(this.extinguishingBottles.lastMinConcessionTemperature)
                },
                lastMinPressure: {
                    minValue: minValue(
                        this.fieldsOptions.lastMinPressure.minimumValue
                    ),
                    maxValue: maxValue(
                        this.fieldsOptions.lastMinPressure.maximumValue
                    ),
                    lesserThan: lesserThan(this.extinguishingBottles.lastMaxPressure)
                },
                lastMaxPressure: {
                    required,
                    minValue: minValue(
                        this.fieldsOptions.lastMaxPressure.minimumValue == 0
                            ? 0.1
                            : this.fieldsOptions.lastMaxPressure.minimumValue
                    ),
                    maxValue: maxValue(
                        this.fieldsOptions.lastMaxPressure.maximumValue
                    ),
                    greaterThan: greaterThan(this.extinguishingBottles.lastMinPressure)
                },
                materialCode: {
                    required
                },
                lastTestDate: {
                    required
                },
                coverage: {
                    maxLength: maxLength(500)
                },
                testPressure: {
                    minValue: minValue(
                        this.fieldsOptions.testPressure.minimumValue == 0
                            ? 0.1
                            : this.fieldsOptions.testPressure.minimumValue
                    ),
                    maxValue: maxValue(
                        this.fieldsOptions.testPressure.maximumValue
                    ),
                    greaterThan: greaterThan(this.extinguishingBottles.lastMaxPressure)
                }
            }
        };
    },
    components: {
        WDecimalField,
        WNumericField
    },
    props: {
        chamberId: {
            type: Number,
            default: 1
        },
        readonlyModus: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        extinguishingBottles: {
            type: Object,
            default: () => {}
        }
    },
    data: () => ({
        dateNow: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10),
        faqDialog: false,
        materialCodeItems: []
    }),
    methods: {
        ...mapActions("attributes", ["getStoredAttributesByKey"]),
        showFaq(searchIndex) {
            this.$root.$faqDialog.open(searchIndex);
        },
        onVolumeFormat(val) {
            this.volume = val;
        },
        onFieldChange(field, value) {
            console.info('---fieldchange', field, value);
            let d = _.cloneDeep(this.extinguishingBottles);
            d = _.set(d, field, value);
            this.onFormChange(d);
        },
        onFormChange(form) {
            this.$emit("form-change", form);
        },
        setFakeData() {
            let d = _.cloneDeep(this.extinguishingBottles);
            d.bottlesCount                 = "3";
            d.volume                       = "800.1";
            d.lastMinPressure              = "0.1";
            d.lastMaxPressure              = "230.1";
            d.lastMinConcessionTemperature = "30.1";
            d.lastMaxConcessionTemperature = "40.1";
            d.testPressure                 = "240.0";
            d.lastTestDate                 = "2024-12-01";
            d.coverage                     = null;
            d.materialCode =
                this.sortedMaterialCodeItems[
                    Math.floor(
                        Math.random() * this.sortedMaterialCodeItems.length
                    )
                ].key;
            this.onFormChange(d);
        },
        getItemsByIndex(index) {
            let materialCodeKey = "stoffcode" + index;
            this.loader().show();
            this.getStoredAttributesByKey(materialCodeKey)
                .then((attributes) => {
                    if (attributes) {
                        this.materialCodeItems = _.clone(attributes);
                    }
                    this.loader().hide();
                })
                .catch((error) => {
                    console.log(error);
                    this.loader().hide();
                    this.$toast.error(
                        this.$t("load-key-error", {
                            keyName: this.$t("material-code")
                        })
                    );
                });
        },
        customFilter(item, queryText) {
            const locale = this.$i18n.locale || "de";
            const itemValue = item.value[locale]
                ? item.value[locale]
                : item.value["de"];
            const itemValueText = itemValue.toLowerCase();
            const searchText = queryText.toLowerCase();
            return itemValueText.indexOf(searchText) > -1;
        },
        getParsedFloatValue(value) {
            return parseFloat(value).toFixed(1);
        },
        reset() {
            this.$v.$reset();
            this.$emit("reset");
        }
    },
    computed: {
        fieldsOptions() {
            return {
                bottlesCount: {
                    overrideMinMaxLimits: 'invalid',
                    minimumValue: "1",
                    maximumValue: "1000"
                },
                volume: {
                    minimumValue: "0",
                    maximumValue: "99999999.9"
                },
                lastMinConcessionTemperature: {
                    minimumValue: "-273.1",
                    maximumValue:
                        this.extinguishingBottles.lastMaxConcessionTemperature &&
                        this.extinguishingBottles.lastMaxConcessionTemperature > 0 < 999.9
                            ? String(this.extinguishingBottles.lastMaxConcessionTemperature)
                            : "999.9"
                },
                lastMaxConcessionTemperature: {
                    minimumValue: "0",
                    maximumValue: "9999.9"
                },
                lastMinPressure: {
                    minimumValue: "-1",
                    maximumValue: this.extinguishingBottles.lastMaxPressure
                        ? String(this.extinguishingBottles.lastMaxPressure)
                        : "9999.9"
                },
                lastMaxPressure: {
                    overrideMinMaxLimits: 'invalid',
                    minimumValue:
                        this.extinguishingBottles.lastMinPressure > 0
                            ? String(this.extinguishingBottles.lastMinPressure)
                            : "0",
                    maximumValue: "9999.9"
                },
                testPressure: {
                    overrideMinMaxLimits: 'invalid',
                    minimumValue: this.extinguishingBottles.lastMaxPressure
                        ? String(this.extinguishingBottles.lastMaxPressure)
                        : "0",
                    maximumValue: "9999.9"
                }
            };
        },
        bottlesCount: {
            get() {
                return this.extinguishingBottles.bottlesCount;
            },
            set(value) {
                this.onFieldChange("bottlesCount", value);
            }
        },
        volume: {
            get() {
                return this.extinguishingBottles.volume;
            },
            set(value) {
                this.onFieldChange("volume", value);
            }
        },
        lastMinConcessionTemperature: {
            get() {
                return this.extinguishingBottles.lastMinConcessionTemperature;
            },
            set(value) {
                this.onFieldChange("lastMinConcessionTemperature", value);
            }
        },
        lastMaxConcessionTemperature: {
            get() {
                console.info('this', this)
                return this.extinguishingBottles.lastMaxConcessionTemperature;
            },
            set(value) {
                this.onFieldChange("lastMaxConcessionTemperature", value);
            }
        },
        lastMinPressure: {
            get() {
                return this.extinguishingBottles.lastMinPressure;
            },
            set(value) {
                this.onFieldChange("lastMinPressure", value);
            }
        },
        lastMaxPressure: {
            get() {
                return this.extinguishingBottles.lastMaxPressure;
            },
            set(value) {
                this.onFieldChange("lastMaxPressure", value);
            }
        },
        materialCode: {
            get() {
                return this.extinguishingBottles.materialCode;
            },
            set(value) {
                this.onFieldChange("materialCode", value);
            }
        },
        lastTestDate: {
            get() {
                return this.extinguishingBottles.lastTestDate;
            },
            set(value) {
                this.onFieldChange("lastTestDate", value);
            }
        },
        coverage: {
            get() {
                return this.extinguishingBottles.coverage;
            },
            set(value) {
                this.onFieldChange("coverage", value);
            }
        },
        testPressure: {
            get() {
                return this.extinguishingBottles.testPressure;
            },
            set(value) {
                this.onFieldChange("testPressure", value);
            }
        },
        testYearItems() {
            let currentYear = new Date().getFullYear();
            let years = [];
            let startYear = currentYear - 150;
            for (var i = startYear; i <= currentYear; i++) {
                years.push(startYear++);
            }
            return years.reverse();
        },
        bottlesCountErrors() {
            const errors = [];
            if (!this.$v.extinguishingBottles.bottlesCount.$dirty) {
                return errors;
            }
            if (!this.$v.extinguishingBottles.bottlesCount.required) {
                errors.push(this.$t("field-required"));
            }
            if (!this.$v.extinguishingBottles.bottlesCount.minValue) {
                errors.push(
                    this.$t("field-min-value", {
                        minValue: this.$v.extinguishingBottles.bottlesCount.$params.minValue.min
                    })
                );
            }
            if (!this.$v.extinguishingBottles.bottlesCount.maxValue) {
                errors.push(
                    this.$t("field-max-value", {
                        maxValue: this.$v.extinguishingBottles.bottlesCount.$params.maxValue.max
                    })
                );
            }
            return errors;
        },
        volumeErrors() {
            const errors = [];
            if (!this.$v.extinguishingBottles.volume.$dirty) {
                return errors;
            }
            if (!this.$v.extinguishingBottles.volume.required) {
                errors.push(this.$t("field-required"));
            }
            if (!this.$v.extinguishingBottles.volume.minValue) {
                errors.push(
                    this.$t("field-min-value", {
                        minValue: this.$v.extinguishingBottles.volume.$params.minValue.min
                    })
                );
            }
            if (!this.$v.extinguishingBottles.volume.maxValue) {
                errors.push(
                    this.$t("field-max-value", {
                        maxValue: this.$v.extinguishingBottles.volume.$params.maxValue.max
                    })
                );
            }

            return errors;
        },
        lastMinConcessionTemperatureErrors() {
            const errors = [];
            if (!this.$v.extinguishingBottles.lastMinConcessionTemperature.$dirty) {
                return errors;
            }
            if (!this.$v.extinguishingBottles.lastMinConcessionTemperature.lesserThan) {
                errors.push(
                    this.$t("field-lesser-than-value", {
                        fieldName: this.$t("last-max-concession-temperature"),
                        maxValue: this.getParsedFloatValue(
                            this.lastMaxConcessionTemperature
                        ),
                        unit: this.$t("label-celsius")
                    })
                );
            }
            if (!this.$v.extinguishingBottles.lastMinConcessionTemperature.minValue) {
                errors.push(
                    this.$t("field-min-value", {
                        minValue:
                            this.$v.extinguishingBottles.lastMinConcessionTemperature.$params
                                .minValue.min
                    })
                );
            }
            if (!this.$v.extinguishingBottles.lastMinConcessionTemperature.maxValue) {
                errors.push(
                    this.$t("field-max-value", {
                        maxValue:
                            this.$v.extinguishingBottles.lastMinConcessionTemperature.$params
                                .maxValue.max
                    })
                );
            }
            return errors;
        },
        lastMaxConcessionTemperatureErrors() {
            const errors = [];
            if (!this.$v.extinguishingBottles.lastMaxConcessionTemperature.$dirty) {
                return errors;
            }
            if (!this.$v.extinguishingBottles.lastMaxConcessionTemperature.required) {
                errors.push(this.$t("field-required"));
            }
            if (!this.$v.extinguishingBottles.lastMaxConcessionTemperature.greaterThan) {
                errors.push(
                    this.$t("field-greater-than-value", {
                        fieldName: this.$t("last-min-concession-temperature"),
                        minValue: this.getParsedFloatValue(
                            this.lastMinConcessionTemperature
                        ),
                        unit: this.$t("label-celsius")
                    })
                );
            }
            if (!this.$v.extinguishingBottles.lastMaxConcessionTemperature.minValue) {
                errors.push(
                    this.$t("field-min-value", {
                        minValue:
                            this.$v.extinguishingBottles.lastMaxConcessionTemperature.$params
                                .minValue.min
                    })
                );
            }
            if (!this.$v.extinguishingBottles.lastMaxConcessionTemperature.maxValue) {
                errors.push(
                    this.$t("field-max-value", {
                        maxValue:
                            this.$v.extinguishingBottles.lastMaxConcessionTemperature.$params
                                .maxValue.max
                    })
                );
            }
            return errors;
        },
        lastMinPressureErrors() {
            const errors = [];
            if (!this.$v.extinguishingBottles.lastMinPressure.$dirty) {
                return errors;
            }
            if (!this.$v.extinguishingBottles.lastMinPressure.lesserThan) {
                errors.push(
                    this.$t("field-lesser-than-value", {
                        fieldName: this.$t("last-max-pressure"),
                        maxValue: this.getParsedFloatValue(
                            this.lastMaxPressure
                        ),
                        unit: this.$t("label-bar")
                    })
                );
            }
            if (!this.$v.extinguishingBottles.lastMinPressure.minValue) {
                errors.push(
                    this.$t("field-min-value", {
                        minValue: this.$v.extinguishingBottles.lastMinPressure.$params.minValue.min
                    })
                );
            }
            if (!this.$v.extinguishingBottles.lastMinPressure.maxValue) {
                errors.push(
                    this.$t("field-max-value", {
                        maxValue: this.$v.extinguishingBottles.lastMinPressure.$params.maxValue.max
                    })
                );
            }
            return errors;
        },
        lastMaxPressureErrors() {
            const errors = [];
            if (!this.$v.extinguishingBottles.lastMaxPressure.$dirty) {
                return errors;
            }
            if (!this.$v.extinguishingBottles.lastMaxPressure.required) {
                errors.push(this.$t("field-required"));
            }
            if (!this.$v.extinguishingBottles.lastMaxPressure.greaterThan) {
                errors.push(
                    this.$t("field-greater-than-value", {
                        fieldName: this.$t("last-min-pressure"),
                        minValue: this.getParsedFloatValue(
                            this.lastMinPressure
                        ),
                        unit: this.$t("label-bar")
                    })
                );
            }
            if (!this.$v.extinguishingBottles.lastMaxPressure.minValue) {
                errors.push(
                    this.$t("field-min-value", {
                        minValue: this.$v.extinguishingBottles.lastMaxPressure.$params.minValue.min
                    })
                );
            }
            if (!this.$v.extinguishingBottles.lastMaxPressure.maxValue) {
                errors.push(
                    this.$t("field-max-value", {
                        maxValue: this.$v.extinguishingBottles.lastMaxPressure.$params.maxValue.max
                    })
                );
            }
            return errors;
        },
        materialCodeErrors() {
            const errors = [];
            if (!this.$v.extinguishingBottles.materialCode.$dirty) {
                return errors;
            }
            if (!this.$v.extinguishingBottles.materialCode.required) {
                errors.push(this.$t("field-required"));
            }
            return errors;
        },
        lastTestDateErrors() {
            const errors = [];
            if (!this.$v.extinguishingBottles.lastTestDate.$dirty) {
                return errors;
            }
            if (!this.$v.extinguishingBottles.lastTestDate.required) {
                errors.push(this.$t("field-required"));
            }
            return errors;
        },
        coverageErrors() {
            const errors = [];
            if (!this.$v.extinguishingBottles.coverage.$dirty) {
                return errors;
            }
            if (!this.$v.extinguishingBottles.coverage.maxLength) {
                errors.push(
                    this.$t("field-max-length", {
                        maxLength:
                            this.$v.extinguishingBottles.coverage.$params.maxLength
                                .max
                    })
                );
            }
            return errors;
        },
        testPressureErrors() {
            const errors = [];
            if (!this.$v.extinguishingBottles.testPressure.$dirty) {
                return errors;
            }
            if (!this.$v.extinguishingBottles.testPressure.greaterThan) {
                errors.push(
                    this.$t("field-greater-than-value", {
                        fieldName: this.$t("last-max-pressure"),
                        minValue: this.getParsedFloatValue(this.lastMaxPressure)
                    })
                );
            }
            if (!this.$v.extinguishingBottles.testPressure.minValue) {
                errors.push(
                    this.$t("field-min-value", {
                        minValue: this.$v.extinguishingBottles.testPressure.$params.minValue.min
                    })
                );
            }
            if (!this.$v.extinguishingBottles.testPressure.maxValue) {
                errors.push(
                    this.$t("field-max-value", {
                        minValue: this.$v.extinguishingBottles.testPressure.$params.maxValue.max
                    })
                );
            }

            return errors;
        },
        sortedMaterialCodeItems() {
            if (this.materialCodeItems.length === 0) return [];
            let locale = this.$i18n.locale || "de";
            return sortAttributesAlphabetically(this.materialCodeItems, locale);
        }
    },
    watch: {
        chamberId: {
            handler: function (newVal) {
                // watch it
                if (newVal) {
                    this.getItemsByIndex(newVal);
                }
            },
            immediate: true
        }
    }
};
</script>
